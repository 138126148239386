import React from "react"
import {Button} from 'antd'
import {inject, observer} from "mobx-react"

import {BaseModal, MainLayoutContentContainer, MainLayoutFooter} from "../../components"
import {ComponentWithInjectedProps} from "../../services/ComponentWithInjectedProps"

import {InjectedProps, Props, State} from "./types"

import './styles.scss'
import {TENANT_DETAILS_LEASE_STEP} from "../../routing/paths";

@inject('StaticPage', 'Inspection')
@observer
export class WelcomePage extends ComponentWithInjectedProps<Props, State, InjectedProps> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isVisibleTermsConditionModal: false,
    }
  }

  nextPage() {
    const property = this.injectedProps.Inspection.inspection.lease.property;
    return (!property.meta_data || !property.meta_data.cords) ? TENANT_DETAILS_LEASE_STEP : this.props.nextPath
  }

  componentDidMount(): void {
    const {StaticPage: {setPage}} = this.injectedProps
    setPage()
  }

  render(): React.ReactElement {
    const {history} = this.props
    const {isVisibleTermsConditionModal} = this.state
    const {Inspection: {inspection: {lease: {property, agency}, tenant}}, StaticPage: {page}} = this.injectedProps

    return (
      <>
        <MainLayoutContentContainer>
          <div className="welcome-page__container">
            <div className="content-wrap">
              <p className="welcome-page__greeting-text_secondary">
                Hi {tenant.full_name},<br/>
                your <strong>routine property inspection</strong> for:
              </p>
            </div>
            <div className="content-wrap address">
              <p className="heading-red__bold-primary">
                {property.address}
              </p>
            </div>
            <p className="ready">is ready to complete</p>
            <div className="support">
              <p className="welcome-page__help-text">
                If you need help click
              </p>
              <a className="call-link" href={`tel:${property.manager.phone_number}`}>
                <i className="la la-phone-alt c-pointer"/>
              </a>
              <p className="welcome-page__help-text">to give me a call</p>
              <p className="welcome-page__manager-text">
                Regards, {property.manager.first_name} {property.manager.last_name}
                <br/>
                {agency.company_name}
              </p>
            </div>
            <p className="welcome-page__terms-text">By clicking “Get Started” you agree
              to our
              <br/>
              <span onClick={() => this.setState({isVisibleTermsConditionModal: true})}>Terms and conditions</span>
            </p>
          </div>
          <BaseModal
            className='terms-modal'
            closable={true}
            centered={true}
            visible={isVisibleTermsConditionModal}
            onClose={() => this.setState({isVisibleTermsConditionModal: false})}>
            <div className={'modal-content'} dangerouslySetInnerHTML={{__html: page.content}}/>
            <div className={'modal-footer'}>
              <Button
                className="t-uppercase app-basic-button_large"
                shape="round"
                type="danger"
                loading={false}
                onClick={() => this.setState({isVisibleTermsConditionModal: false})}
              >
                Close
              </Button>
            </div>
          </BaseModal>
        </MainLayoutContentContainer>
        <MainLayoutFooter>
          <div className="welcome-page__buttons-container">
            <Button
              className="fullwidth t-uppercase app-basic-button_large"
              shape="round"
              type="danger"
              loading={false}
              onClick={() => history.push(this.nextPage())}
            >
              Ok
            </Button>
          </div>
        </MainLayoutFooter>
      </>
    )
  }
}
