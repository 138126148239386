import React from "react";

export function Comment(props = {color: '#F93A73'}) {
    return (
        <svg width="25px"
             height="25px"
             viewBox="0 0 30 22"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons---Routine-Inspection" transform="translate(-422.000000, -181.000000)"
                   fill={props.color}>
                    <g id="Group-10" transform="translate(422.000000, 181.000000)">
                        <path d="M25.668924,5.763213 L3.580854,5.763213 C3.265644,5.763213 3.010854,5.507853 3.010854,5.193213 C3.010854,4.878573 3.265644,4.623213 3.580854,4.623213 L25.668924,4.623213 C25.984134,4.623213 26.238924,4.878573 26.238924,5.193213 C26.238924,5.507853 25.984134,5.763213 25.668924,5.763213 Z M25.668924,10.458189 L3.580854,10.458189 C3.265644,10.458189 3.010854,10.202829 3.010854,9.888189 C3.010854,9.573549 3.265644,9.318189 3.580854,9.318189 L25.668924,9.318189 C25.984134,9.318189 26.238924,9.573549 26.238924,9.888189 C26.238924,10.202829 25.984134,10.458189 25.668924,10.458189 Z M25.668924,15.153165 L3.580854,15.153165 C3.265644,15.153165 3.010854,14.897805 3.010854,14.583165 C3.010854,14.268525 3.265644,14.013165 3.580854,14.013165 L25.668924,14.013165 C25.984134,14.013165 26.238924,14.268525 26.238924,14.583165 C26.238924,14.897805 25.984134,15.153165 25.668924,15.153165 Z M2.234343,1.71 C1.630713,1.71 1.139943,2.20077 1.139943,2.8044 L1.139943,16.97175 C1.139943,17.57538 1.630713,18.06615 2.234343,18.06615 L13.154403,18.06615 C13.358463,18.06615 13.546563,18.17502 13.648023,18.35115 L14.625003,20.04348 L15.601983,18.35115 C15.704013,18.17502 15.892113,18.06615 16.095603,18.06615 L27.405543,18.06615 C28.009173,18.06615 28.499943,17.57538 28.499943,16.97175 L28.499943,2.8044 C28.499943,2.20077 28.009173,1.71 27.405543,1.71 L2.234343,1.71 Z M14.625003,21.75348 C14.421513,21.75348 14.233413,21.64518 14.131383,21.46848 L12.825513,19.20615 L2.234343,19.20615 C1.002573,19.20615 -5.70000026e-05,18.20409 -5.70000026e-05,16.97175 L-5.70000026e-05,2.8044 C-5.70000026e-05,1.57263 1.002573,0.57 2.234343,0.57 L27.405543,0.57 C28.637313,0.57 29.639943,1.57263 29.639943,2.8044 L29.639943,16.97175 C29.639943,18.20409 28.637313,19.20615 27.405543,19.20615 L16.425063,19.20615 L15.118623,21.46848 C15.017163,21.64518 14.829063,21.75348 14.625003,21.75348 Z"
                              id="Combined-Shape"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}
