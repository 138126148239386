import React, {Component} from "react"
import {withRouter} from 'react-router'
import {inject, observer} from "mobx-react"
import {Icon, Spin} from 'antd'
//@ts-ignore
import Div100vh from 'react-div-100vh'

import BottomDrawer from "../../drawers/BottomDrawer/BottomDrawer"

import {InjectedProps, Props, State} from "./types"

import {getItem} from "../../../helpers/storage"
import {setJwtToken} from "../../../networking/core"

import {CAMERA_ISSUE, CONFIRMATION_STEP, CONGRATULATION_STEP, EXPIRED_PAGE, INDEX_PAGE} from "../../../routing/paths";

import './styles.scss'
import {LogoWithText} from "../../icons";


const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>;

@inject('Core', 'Lease', 'Inspection')
@observer
class MainLayout extends Component<Props, State> {

  state = {
    loading: false,
    logoError: false
  }

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount(): void {
    this.handleLoadingInspection();
  }

  handleLoadingInspection() {
    const {loadedInspection} = this.props
    if (loadedInspection && ![CONGRATULATION_STEP, CAMERA_ISSUE].includes(window.location.pathname)) {
      const token = getItem('token')
      if (token) {
        setJwtToken(token)
      }
      this.setState({loading: true}, () => {
        this.injected.Inspection.loadInspection()
          .then((response) => this.handleAgencyBranding(response.lease.agency))
          .catch(({response}) => {
            if (response.status === 423) {
              return this.props.history.push(CONGRATULATION_STEP)
            }
            return this.props.history.push(EXPIRED_PAGE)
          })
          .finally(() => this.setState({loading: false}))
      })
    }
  }

  handleAgencyBranding(agency: { accent_colour: string, office_logo: string }) {
    const {accent_colour, office_logo} = agency
    this.injected.Core.setBranding({
      accent_color: accent_colour,
      logo: office_logo
    });
    document.documentElement.style.setProperty('--primary-color', accent_colour ?? '#F93A73');
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render(): React.ReactElement {
    const {children, history, location} = this.props
    const {loading, logoError} = this.state
    const {Core, Inspection} = this.injected
    const showBackButton: boolean = ![INDEX_PAGE, CONGRATULATION_STEP, CONFIRMATION_STEP, CAMERA_ISSUE].includes(location.pathname)
    const showCallButton: boolean = ![CONFIRMATION_STEP, CONGRATULATION_STEP, CAMERA_ISSUE].includes(location.pathname)

    return (
      <Div100vh style={{
        minHeight: '100rvh',
        backgroundColor: '#fff',
        display: 'flex',
        maxWidth: 991,
        margin: '0 auto'
      }}>
        {
          loading ?
            <div className="loading-container">
              <Spin indicator={antIcon}/>
            </div>
            :
            <div className={`main-layout__container`}>
              {location.pathname !== INDEX_PAGE && <div className="main-layout__header">
                <div>
                  <i
                    style={{visibility: showBackButton ? 'visible' : 'hidden'}}
                    onClick={() => history.goBack()}
                    className="la la-angle-left c-pointer"/>
                </div>
                <div className="logo-block">
                  <>
                    {Core.branding.logo && !logoError
                      ?
                      <div><img alt={'Property Space'} onError={() => this.setState({logoError: true})} src={Core.branding.logo}/>
                      </div>
                      : <>
                        <div>
                          <LogoWithText color={Core.branding.accent_color ?? '#F93A73'}/>
                        </div>
                        <div className="logo-text">
                          Tenant Assisted Inspection
                        </div>
                      </>
                    }
                  </>
                </div>
                <div>
                  {showCallButton &&
                  <i className="la la-phone-alt c-pointer" onClick={() => {
                    Core.toggleCallDialog()
                  }}/>}
                </div>
              </div>}
              {children}
            </div>
        }

        {Inspection.inspection && <BottomDrawer
          inspection={Inspection.inspection}
          onClose={() => Core.toggleCallDialog()} visible={Core.VisibleCallDialog}/>
        }
      </Div100vh>
    )
  }
}

const MainLayoutWithRouter = withRouter(MainLayout)

export {MainLayoutWithRouter}
