import React from 'react'

export function Tick() {
    return (
        <svg width="16px"
             height="16px"
             viewBox="0 0 19 19"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="tick2">
                    <g id="tick" transform="translate(0.000000, -0.000000)">
                        <g>
                            <ellipse id="Oval" fill="#3CBE00" cx="9.5" cy="9.5" rx="9.5"
                                     ry="9.49376562"/>
                            <polygon id="Path" fill="#FFFFFF" fillRule="nonzero"
                                     points="8.73821875 14.5498438 4.2465 11.0505781 5.52365625 9.41153125 8.28192188 11.5603125 12.8342031 4.99135938 14.5424219 6.17470313"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
