import React from "react";

export function Utilities(props = {color: '#F93A73'}) {
  return (
    <svg width="25px"
         height="25px"
         viewBox="0 0 30 30"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons---Routine-Inspection" transform="translate(-347.000000, -176.000000)"
           fill={props.color}>
          <path
            d="M347.728483,190.422957 C347.951206,182.929245 353.947479,176.885217 361.422131,176.588141 L361.422131,176.576928 C361.422131,176.257887 361.680013,176 361.999048,176 C362.318083,176 362.575966,176.257887 362.575966,176.576928 L362.575966,176.588138 C370.051734,176.885172 376.048599,182.929217 376.271343,190.422957 L376.423082,190.422957 C376.742118,190.422957 377,190.681421 377,190.999885 C377,191.318349 376.742118,191.576812 376.423082,191.576812 L376.259832,191.576812 C375.893449,198.936098 369.955182,204.828816 362.575966,205.122014 L362.575966,205.423072 C362.575966,205.741536 362.318083,206 361.999048,206 C361.680013,206 361.422131,205.741536 361.422131,205.423072 L361.422131,205.122011 C354.044017,204.828772 348.106341,198.93607 347.739993,191.576812 L347.576918,191.576812 C347.257882,191.576812 347,191.318349 347,190.999885 C347,190.681421 347.257882,190.422957 347.576918,190.422957 L347.728483,190.422957 Z M348.89551,191.576812 C349.261001,198.288186 354.692086,203.674482 361.422125,203.967082 L361.422131,198.172824 C361.422131,197.853783 361.680013,197.595897 361.999048,197.595897 C362.318083,197.595897 362.575966,197.853783 362.575966,198.172824 L362.575966,203.967084 C369.306602,203.674508 374.73875,198.288202 375.104313,191.576812 L369.171806,191.576812 C368.852771,191.576812 368.594889,191.318349 368.594889,190.999885 C368.594889,190.681421 368.852771,190.422957 369.171806,190.422957 L375.116907,190.422957 C374.894342,183.577002 369.403159,178.039812 362.575971,177.743067 L362.575966,183.827176 C362.575966,184.14564 362.318083,184.404103 361.999048,184.404103 C361.680013,184.404103 361.422131,184.14564 361.422131,183.827176 L361.422131,177.743068 C354.595537,178.039838 349.10544,183.577018 348.882919,190.422957 L354.826463,190.422957 C355.145499,190.422957 355.403381,190.681421 355.403381,190.999885 C355.403381,191.318349 355.145499,191.576812 354.826463,191.576812 L348.89551,191.576812 Z"
            id="Combined-Shape"/>
        </g>
      </g>
    </svg>
  )
}
