import React from "react"
import {inject, observer} from "mobx-react"
import {Button, Collapse, message} from 'antd'
import {last, trimEnd} from 'lodash'

import {InjectedProps, Props} from "./types"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"
import {LeaseStepsContainer} from ".."
import {Area} from "../../../types/Area";
import {AreaCart} from "./AreaCart";
import {Comment, Maintenance, Tick} from "../../../components/icons";
import {generic, mapping} from "./area_mapping";
import {CommentCart} from "./CommentCart";
import {BaseModal} from "../../../components";
import {CameraPicker} from "../../../components/images/CameraPicker/CameraPicker";
import {ImageGallery} from "../../../components/images/ImageGallery/ImageGallery";

import './styles.scss'
import {handleInputFocus} from "../../../helpers/scroll";
import {Carousel} from "../../../components/carousel/Carousel";


const {Panel} = Collapse;

@inject('Inspection', 'Core')
@observer
export class FillReportPage extends ComponentWithInjectedProps<Props, any, InjectedProps> {

  primaryColor: string = '';

  state = {
    isPreviewModalVisible: false
  };

  openedAreas: Array<string> = [];

  getAreaIcon = (alias: string) => {
    const icon = mapping.find((area: any) => {
      const cleaned = trimEnd(alias.replace(/[0-9]/g, '')).toLocaleLowerCase();
      return cleaned === area.alias || cleaned.split('_').includes(area.alias)
    });
    const Component = icon ? icon.component : generic;
    const styles = icon ? icon.styles || {} : {};
    return <span className="area-icon" style={styles}> <Component color={this.primaryColor}/> </span>
  }

  onCollapseChange = (areas: Array<string> | string) => {
    let ids = areas instanceof String ? areas.split(',') : areas;

    // @ts-ignore
    this.handleAreasChange(ids);
    // @ts-ignore
    this.injectedProps.Core.setOpenedAreas(ids)
  }

  handleAreasChange(ids: Array<string>) {
    // @ts-ignore
    const lastIndex: string = last(ids)
    if (ids.length > this.openedAreas.length && lastIndex !== undefined) {
      this.handleWindowScroll(
        parseInt(lastIndex)
      )
    }
    this.openedAreas = ids;
  }

  handleWindowScroll(index: number) {
    const item = document
      .getElementsByClassName('ant-collapse-item').item(index)
    // @ts-ignore
    const header = item.getElementsByClassName('ant-collapse-header').item(0)
    // @ts-ignore
    header.scrollIntoView({
      behavior: 'smooth', block: 'center'
    })
  }

  handleDoneClick() {
    if (!this.injectedProps.Inspection.allAreasHasImages) {
      return message.warn('You are required to add at least one photo per area');
    }
    const {history, nextPath} = this.props
    history.push(nextPath)
  }

  shouldShowPreviewModal() {
    if (!sessionStorage.getItem('viewed_demo')) {
      this.setState({
        isPreviewModalVisible: true
      });
    }
  }

  handlePreviewModalClose() {
    this.setState({
      isPreviewModalVisible: false
    });
    sessionStorage.setItem('viewed_demo', 'true')
  }

  componentDidMount(): void {
    this.shouldShowPreviewModal();
  }

  render(): React.ReactElement {
    this.primaryColor = this.injectedProps.Core.branding.accent_color ? this.injectedProps.Core.branding.accent_color : '#F93A73';
    const {getAreaIcon, onCollapseChange} = this;
    const {isPreviewModalVisible} = this.state
    const {Inspection: {inspection: {config, lease: {property: {areas}}}}, Core} = this.injectedProps
    const collapsed = this.injectedProps.Core.collapsedAreas

    return (
      <LeaseStepsContainer title={''} background={false}>
        {{
          content: (
            <div className="report-step__container">
              <Collapse
                onChange={(key) => onCollapseChange(key)}
                bordered={false}
                expandIconPosition={"right"}
                defaultActiveKey={collapsed}
              >
                {areas.map((area: Area, index: number) => {
                  return (
                    <Panel
                      key={index}
                      header={
                        <>
                          {getAreaIcon(area.alias)}
                          {area.title}
                          {area.uploaded_files.length > 0 &&
                          <span className="tick-icon"><Tick/></span>}
                        </>
                      }>
                      <AreaCart area={area}
                                index={index}
                                onFocus={(className => handleInputFocus(className))}/>
                    </Panel>
                  )
                })}
                {config.is_maintenance_comment_active &&
                <Panel
                  key={areas.length}
                  header={
                    <>
                                                <span className="area-icon"
                                                      style={{top: 2}}> <Maintenance color={this.primaryColor}/> </span>
                      Requested maintenance
                    </>
                  }>
                  <CommentCart
                    alias="maintenance"
                    onFocus={(className => handleInputFocus(className))}/>
                </Panel>
                }
                {config.is_general_comment_active &&
                <Panel
                  key={areas.length + 1}
                  header={
                    <>
                      <span className="area-icon" style={{top: 2}}> <Comment color={this.primaryColor}/> </span>
                      General comment
                    </>
                  }>
                  <CommentCart
                    alias="general"
                    onFocus={(className => handleInputFocus(className))}/>
                </Panel>
                }
              </Collapse>
              {Core.isCameraVisible && <BaseModal
                className="full-screen-modal"
                visible={Core.isCameraVisible}>
                <CameraPicker/>
              </BaseModal>
              }
              {Core.isGalleryVisible &&
              <BaseModal
                className="full-screen-modal"
                visible={Core.isGalleryVisible}>
                <ImageGallery index={Core.galleryIndex}/>
              </BaseModal>
              }
              <BaseModal
                visible={isPreviewModalVisible}
                centered={true}
                className={'carousel-modal'}>
                <Carousel onClose={() => this.handlePreviewModalClose()}/>
              </BaseModal>
            </div>
          ),
          footer: (
            <div className="footer__buttons-container_two">
              <div>
                <Button
                  className="fullwidth t-uppercase app-basic-button_large"
                  shape="round"
                  type="danger"
                  loading={false}
                  onClick={() => this.handleDoneClick()}
                >
                  I'm done
                </Button>
              </div>
            </div>
          )
        }}
      </LeaseStepsContainer>
    )
  }
}
