import React from 'react'

export function Generic(props = {color: '#F93A73'}) {
    return (
        <svg width="25px"
             height="25px"
             viewBox="0 0 30 30"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons---Routine-Inspection" transform="translate(-22.000000, -99.000000)"
                   fill={props.color}>
                    <path d="M37,100.153846 C29.3655769,100.153846 23.1538462,106.365577 23.1538462,114 C23.1538462,121.634423 29.3655769,127.846154 37,127.846154 C44.6344231,127.846154 50.8461538,121.634423 50.8461538,114 C50.8461538,106.365577 44.6344231,100.153846 37,100.153846 Z M37,129 C28.7292308,129 22,122.270769 22,114 C22,105.729231 28.7292308,99 37,99 C45.2707692,99 52,105.729231 52,114 C52,122.270769 45.2707692,129 37,129 Z M37,106.532423 C32.8825,106.532423 29.5323077,109.882615 29.5323077,114.000115 C29.5323077,118.117038 32.8825,121.466654 37,121.466654 C41.1175,121.466654 44.4676923,118.117038 44.4676923,114.000115 C44.4676923,109.882615 41.1175,106.532423 37,106.532423 Z M37,122.6205 C32.2461538,122.6205 28.3784615,118.753385 28.3784615,114.000115 C28.3784615,109.246269 32.2461538,105.378577 37,105.378577 C41.7538462,105.378577 45.6215385,109.246269 45.6215385,114.000115 C45.6215385,118.753385 41.7538462,122.6205 37,122.6205 Z"
                          id="Combined-Shape"/>
                </g>
            </g>
        </svg>
    )
}
