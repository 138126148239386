import {observable, action, computed} from "mobx"

import {IInspection} from "./types"
import {Inspection} from "../../types/Inspection";
import {
    deleteFile,
    getInspection,
    areaComment,
    inspectionComment
} from "../../networking/inspection";
import {Area} from "../../types/Area";

export class InspectionStore implements IInspection {

    @observable inspection: Inspection = {
        config: {
            is_general_comment_active: true,
            is_maintenance_comment_active: true,
        },
        general_comment: '',
        maintenance_comment: '',
        tenant: {
            full_name: '',
            phone_number: '',
            email: ''
        },
        lease: {
            start_date: '',
            end_date: '',
            full_rent: 0,
            agency: {
                company_name: '',
                office_logo: '',
                website: ''
            },
            property: {
                areas: [{
                    id: 0,
                    title: '',
                    alias: '',
                    area_comment: {
                        comment: ''
                    },
                    uploaded_files: [{
                        id: 0,
                        full_path: ''
                    }]
                }],
                address: '',
                additional_address: '',
                formatted_address: '',
                state: '',
                city: '',
                post_code: '',
                manager: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    avatar: null,
                },
                meta_data: {
                    cords: {
                        lng: 0,
                        lat: 0
                    }
                }
            }
        }
    };

    @observable activeArea = 0

    @action
    setInspection(inspection: Inspection) {
        this.inspection = inspection
    }

    @action
    setActiveArea(area: number) {
        this.activeArea = area
    }

    @action
    getActiveAreaObj(): Area {
        // @ts-ignore
        return this.inspection.lease.property.areas
            .find((area: Area) => area.id === this.activeArea)
    }

    @action
    appendAreaUploadedFile(file: any) {
        // to get pre-rendered image
        this.getActiveAreaObj()
            .uploaded_files
            .push(file)
    }

    @action
    deleteAreaUploadedFile(index: number) {
        const area = this.getActiveAreaObj();
        const file = area.uploaded_files[index];

        return deleteFile(file.id)
            .then(() => {
                area.uploaded_files.splice(index, 1)
            });
    }

    @computed
    get allAreasHasImages() {
        return this.inspection.lease.property.areas
            .filter((area: Area) => {
                return area.uploaded_files.length === 0
            }).length === 0;
    }

    @action
    addAreaComment(alias: string, comment: string) {
        const activeArea = this.inspection.lease.property.areas.find((area: Area) => {
            return area.alias === alias
        });
        areaComment(alias, comment).then((res) => {
            // @ts-ignore
            activeArea.area_comment = res.data
        })
    }

    @action
    addComment(section: string, comment: string) {
        const alias = `${section}_comment`;
        inspectionComment(alias, comment).then((res) => {
            // @ts-ignore
            this.inspection[alias] = res.data.comment
        })
    }

    loadInspection() {
        return getInspection()
            .then((response) => {
                return this.inspection = response.data
            })
    }
}
