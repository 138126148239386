// @ts-ignore
window.Clipboard = (function (window, document, navigator) {
	// @ts-ignore
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text: string) {
        textArea = document.createElement('textArea');
		// @ts-ignore
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
			// @ts-ignore
            range.selectNodeContents(textArea);
            selection = window.getSelection();
			// @ts-ignore
            selection.removeAllRanges(); selection.addRange(range); textArea.setSelectionRange(0, 999999);
        } else {
			// @ts-ignore
            textArea.select();
        }
    }

    function copyToClipboard() {
        document.execCommand('copy');
		// @ts-ignore
        document.body.removeChild(textArea);
    }

    copy = function (text: string) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
