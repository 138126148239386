import React from "react";

export function Exterior(props = {color: '#F93A73'}) {
    return (
        <svg width="25px"
             height="25px"
             viewBox="0 0 30 30"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons---Routine-Inspection" transform="translate(-355.000000, -21.000000)"
                   fill={props.color}>
                    <path d="M382.981284,22.1856294 C382.895682,22.1648606 382.806309,22.1538528 382.714413,22.1538528 L374.207463,22.1538528 C373.888611,22.1538528 373.630877,21.8953898 373.630877,21.5769264 C373.630877,21.2578861 373.888611,21 374.207463,21 L382.714413,21 C383.97483,21 385,22.0263521 385,23.2875132 L385,31.7989085 C385,32.1173718 384.742266,32.3758349 384.423414,32.3758349 C384.104562,32.3758349 383.846828,32.1173718 383.846828,31.7989085 L383.846828,23.2875132 C383.846828,23.1847811 383.833121,23.0852092 383.807441,22.9905316 L369.289704,37.5179799 C369.177269,37.6304806 369.029663,37.6870193 368.882057,37.6870193 C368.734451,37.6870193 368.586845,37.6304806 368.474411,37.5179799 C368.248966,37.2924017 368.248966,36.9277842 368.474411,36.702206 L382.981284,22.1856294 Z M380.829323,51 L357.285587,51 C356.02517,51 355,49.9736479 355,48.7124868 L355,25.1554278 C355,23.8942667 356.02517,22.8679146 357.285587,22.8679146 L369.05832,22.8679146 C369.377172,22.8679146 369.634906,23.1263777 369.634906,23.444841 C369.634906,23.7638813 369.377172,24.0217674 369.05832,24.0217674 L357.285587,24.0217674 C356.661144,24.0217674 356.153172,24.5306165 356.153172,25.1554278 L356.153172,48.7124868 C356.153172,49.337875 356.661144,49.8461472 357.285587,49.8461472 L380.829323,49.8461472 C381.454342,49.8461472 381.962891,49.337875 381.962891,48.7124868 L381.962891,36.9345342 C381.962891,36.6160709 382.220625,36.3576078 382.539477,36.3576078 C382.858329,36.3576078 383.116063,36.6160709 383.116063,36.9345342 L383.116063,48.7124868 C383.116063,49.9736479 382.090316,51 380.829323,51 Z"
                          id="Combined-Shape"/>
                </g>
            </g>
        </svg>
    )
}
