import * as Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

export const MAX_CAMERA_PHOTOS_COUNT = browser.getOSName(true) === 'android' ? 15 : 25;


export function isBrowserCompatible() {
    if (browser.getOSName(true) === 'ios') {
        return browser.getBrowserName().toLocaleLowerCase() === 'safari';
    }
    return true
}
