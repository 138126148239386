import axios, {AxiosResponse} from 'axios'
import {message} from "antd"
import bugsnag from '@bugsnag/js'

import {removeItem} from "../helpers/storage"
import {INVALID_PAGE} from "../routing/paths";

declare const LEASE_API_URL: string
declare const BUGSNAG_API_KEY: string

/**
 * @class AxiosMiddleware
 */
export class AxiosMiddleware {
    /**
     * Boot the middleware
     */
    static boot(): void {
        axios.defaults.baseURL = LEASE_API_URL
        axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
        axios.defaults.headers['X-CLIENT-ID'] = 'react_app'
        axios.defaults.headers['Content-type'] = "application/json"

        // register interceptors to handle all response from server
        axios.interceptors.response.use(function (response: AxiosResponse): AxiosResponse {
            return response;
        }, function (error) {
            if (error.response && error.response.status >= 500) {
                AxiosMiddleware.report(error)
            }
            if (error.response && error.response.status > 400) {
                switch (error.response.status) {
                    // in case if user is not authenticated
                    // or session is expired, we will redirect user to main page
                    // and there backend will redirect user next
                    case 401:
                        /** UNAUTHORIZED @see https://httpstatuses.com/401 */
                        //@ts-ignore
                        removeItem('token');
                        return setTimeout(() => {
                            return window.location.pathname = INVALID_PAGE
                        }, 3000);
                    // csrf token as well have expiration
                    // in case if token is expired we will just redirect the page
                    case 419: /** Custom laravel error in case if  csrf token is expired */
                        //@ts-ignore
                        return window.location.reload();
                    case 429:
                        /** TOO MANY REQUESTS @see https://httpstatuses.com/429 */
                        message.error({
                            content: "You are sending to many requests.\nPlease, try again later."
                        })
                }
            }
            return Promise.reject(error);
        });
    }

    static report(error: any) {
        if (process.env.NODE_ENV !== 'development') {
            const bugsnagClient = bugsnag({
                apiKey: BUGSNAG_API_KEY,
            })
            bugsnagClient.notify(error.response)
        }
        message.error({
            content: 'Something went wrong, please try again later'
        })
    }
}
