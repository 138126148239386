import React from "react";
import {inject} from "mobx-react";
//@ts-ignore
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import {AxiosResponse} from 'axios';
import {Props, State, InjectedProps} from './types'
import {TopBar} from "../TopBar/TobBar";
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";

import './styles.scss'
import {uploadFile} from "../../../networking/inspection";
import {message} from "antd";
import {MAX_CAMERA_PHOTOS_COUNT} from "./helpers";

@inject('Core', 'Inspection')
export class CameraPicker extends ComponentWithInjectedProps<Props, State, InjectedProps> {
    state = {
        uploadedFilesCount: 0
    }

    componentDidMount(): void {
        const count = this.injectedProps.Inspection.getActiveAreaObj().uploaded_files.length;
        this.setState({
            uploadedFilesCount: count
        })
    }

    upload(dataUri: string) {
        // not valid data uri
        if (!dataUri.replace('data:,', '').length) {
            return;
        }
        const area = this.injectedProps.Inspection.getActiveAreaObj();
        if (this.state.uploadedFilesCount === MAX_CAMERA_PHOTOS_COUNT) {
            return this.handleLimitClose();
        }
        this.setState({
            uploadedFilesCount: this.state.uploadedFilesCount + 1
        });

        // As IM always removes and re-creates areas from scratch
        // we cannot behave on area_id, and should use string name instead
        // back-end will prepend all uploaded files to that area
        // @ts-ignore
        uploadFile(dataUri, area.alias)
            .then((res) => {
                this.preRenderImage(res);
                this.injectedProps.Inspection.appendAreaUploadedFile(res.data);
                if (area.uploaded_files.length === MAX_CAMERA_PHOTOS_COUNT) {
                    this.handleLimitClose();
                }
            })
    }

    preRenderImage(response: AxiosResponse<any>) {
        const img = new Image();
        img.src = response.data.full_path
    }

    handleLimitClose() {
        message.info('You have reached the limit of uploaded photos for this area');
        this.handleClose();
    }

    handleClose() {
        const {Core} = this.injectedProps;
        Core.toggleCameraPicker();
    }

    render(): React.ReactElement {
        const {uploadedFilesCount} = this.state
        return (
            <>
                <TopBar onClose={() => this.handleClose()}/>
                <Camera
                    isMaxResolution={true}
                    isSilentMode={true}
                    isFullscreen={true}
                    isImageMirror={false}
                    imageType={IMAGE_TYPES.JPG}
                    isDisplayStartCameraError={true}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    onTakePhoto={(dataUri: string) => this.upload(dataUri)}
                />
                <div className='camera-picker__bottom-counter'>
                    {uploadedFilesCount} of {MAX_CAMERA_PHOTOS_COUNT}
                </div>
                <div className='camera-picker__bottom-done'
                     onClick={() => this.handleClose()}>
                    <span>I'm done</span>
                </div>
            </>
        );
    }
}
