import React, {Component} from "react"
import {Icon, Spin} from 'antd'
//@ts-ignore
import Div100vh from 'react-div-100vh'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"

import {Props, State} from "./types"

import logo from '../../resources/images/svg/logo-with-text.svg'

import './styles.scss'

import {verificationTenant} from "../../networking/verification"
import {setItem} from "../../helpers/storage"
import {CAMERA_ISSUE, CONGRATULATION_STEP, EXPIRED_PAGE, INDEX_PAGE} from "../../routing/paths";
import {isBrowserCompatible} from "../../components/images/CameraPicker/helpers";

const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>;

export class VerificationTenantPage extends Component<Props, State> {

  componentDidMount(): void {
    this.verifyCode();
  }

  verifyCode() {
    const {match: {params: {code}}, history} = this.props
    verificationTenant(code)
      .then(res => {
        this.handleValidCode(res)
      })
      .catch((err) => {
        if (err.response.status === 423) {
          return history.replace(CONGRATULATION_STEP);
        }
        history.replace(EXPIRED_PAGE)
      })
  }

  handleValidCode(res: any) {
    const {match: {params: {code}}, history, location} = this.props
    if (!isBrowserCompatible() && location.pathname !== CAMERA_ISSUE) {
      return history.push(`${CAMERA_ISSUE}#${code}`)
    }
    setItem('token', res.data.token)
    history.replace(INDEX_PAGE)
  }

  render(): React.ReactElement {
    return (
      <>
        <Div100vh style={{
          minHeight: '100rvh',
          backgroundColor: '#fff',
          display: 'flex',
          maxWidth: 991,
          margin: '0 auto'
        }}>
          <MainLayoutContentContainer>
            <div className="verification-tenant-page__container">
              <img src={logo} alt="Logo" className="verification-tenant-page__logo"/>
              <h3 className="verification-tenant-page__greeting-text">Loading...</h3>
              <p className="welcome-page__greeting-text_secondary">
                Please wait
              </p>
              <Spin indicator={antIcon}/>
            </div>
          </MainLayoutContentContainer>
          <MainLayoutFooter>
            {/*...*/}
          </MainLayoutFooter>
        </Div100vh>
      </>
    )
  }
}
