import * as components from '../pages'
import * as paths from './paths'
import {Route} from "./types"

export const routes: Array<Route> = [
    {
        path: paths.INDEX_PAGE,
        component: components.IndexPage,
        nextPath: paths.WELCOME_PAGE,
        isLeaseSteps: false,
        exact: true,
        private: true
    },{
        path: paths.WELCOME_PAGE,
        component: components.WelcomePage,
        nextPath: paths.PROPERTY_DETAILS_LEASE_STEP,
        isLeaseSteps: false,
        exact: true,
        private: true
    },
    {
        path: paths.PROPERTY_DETAILS_LEASE_STEP,
        component: components.PropertyWithMapsStep,
        nextPath: paths.TENANT_DETAILS_LEASE_STEP,
        isLeaseSteps: true,
        private: true
    },
    {
        path: paths.TENANT_DETAILS_LEASE_STEP,
        component: components.TenantDetailsSteps,
        nextPath: paths.FILL_REPORT_STEP,
        isLeaseSteps: true,
        private: true
    },
    {
        path: paths.FILL_REPORT_STEP,
        component: components.FillReportPage,
        nextPath: paths.CONFIRMATION_STEP,
        isLeaseSteps: true,
        private: true
    },
    {
        path: paths.CONFIRMATION_STEP,
        component: components.ConfirmationStep,
        nextPath: paths.CONGRATULATION_STEP,
        isLeaseSteps: false,
        private: true
    },
    {
        path: paths.CONGRATULATION_STEP,
        component: components.CongratulationStep,
        nextPath: '',
        isLeaseSteps: false,
        private: false
    },
    {
        path: paths.CAMERA_ISSUE,
        component: components.CameraIssue,
        nextPath: '',
        isLeaseSteps: false,
        private: false
    },
];
