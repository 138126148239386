import React from "react"
import {Button} from 'antd'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"
import {ComponentWithInjectedProps} from "../../services/ComponentWithInjectedProps"

import {InjectedProps, Props, State} from "./types"

import './styles.scss'
import {WELCOME_PAGE} from "../../routing/paths";
import {Card, LogoWithText} from "../../components/icons";
import {inject} from "mobx-react";

@inject('Core')
export class IndexPage extends ComponentWithInjectedProps<Props, State, InjectedProps> {

  state = {
    logoError: false
  }

  render(): React.ReactElement {
    const {history} = this.props
    const {logoError} = this.state
    const {Core: {branding}} = this.injectedProps
    return (
      <>
        <MainLayoutContentContainer>
          <div className="index-page__container">
            {branding.logo && !logoError
              ? <img alt={'Property Space'} src={branding.logo} onError={() => this.setState({logoError: true})}/>
              : <LogoWithText color={branding.accent_color ?? '#F93A73'}/>}
            <h1 className="title">Welcomes you to your</h1>
            <span className="subtitle">Tenant Assisted Inspection</span>
            <div className="card-image">
              <Card/>
            </div>
          </div>
        </MainLayoutContentContainer>
        <MainLayoutFooter>
          <div className="welcome-page__buttons-container">
            <Button
              className="fullwidth t-uppercase app-basic-button_large"
              shape="round"
              type="danger"
              loading={false}
              onClick={() => history.push(WELCOME_PAGE)}
            >
              Get Started
            </Button>
          </div>
        </MainLayoutFooter>
      </>
    )
  }
}
