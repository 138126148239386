import React from "react"
import {Modal} from 'antd'

import {Props} from "./types"

import './styles.scss'

export function BaseModal(props: Props): React.ReactElement {
    const {children, visible, className, width, centered = false, closable = false, onClose} = props
    const onCancel = () => {
        if (closable && onClose !== undefined) {
            onClose()
        }
    };
    return (
        <Modal
            width={width}
            footer={null}
            closable={closable}
            onCancel={onCancel}
            maskClosable={closable}
            visible={visible}
            centered={centered}
            className={className}
        >
            {children}
        </Modal>
    )
}
