export const handleInputFocus = (className: string) => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        setTimeout(() => {
            const element = document.getElementsByClassName(className)
                .item(0)
            // @ts-ignore
            element.scrollIntoView({
                behavior: 'auto',
                block: 'center'
            })
        }, window.innerHeight < 680 ? 150 : 100)
    }
};
