import React from 'react'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../../components"
import * as Icons from "../../../components/icons"

import {Props} from "./types"

import './styles.scss'

export function CongratulationStep(props: Props) {

    return (
        <>
            <MainLayoutContentContainer>
                <div className="congratulation-page__container">
                    <h3 className="heading-red__bold-secondary">
                        Your routine inspection is now complete
                    </h3>
                    <Icons.Congratulation/>
                    <p className="heading-secondary">
                        Your inspection report has been sent back to your property manager
                    </p>
                </div>
            </MainLayoutContentContainer>
            <MainLayoutFooter>

            </MainLayoutFooter>
        </>
    )
}
