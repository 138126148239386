import * as icons from '../../../components/icons/Areas'

export const generic = icons.Generic;

export const mapping = [
    {
        alias: 'bathroom',
        component: icons.Bathroom,
    },
    {
        alias: 'bedroom',
        component: icons.Bedroom,
        styles: {
            top: 2
        }
    },
    {
        alias: 'ceiling',
        component: icons.Ceiling,
        styles: {
            top: 5
        }
    },
    {
        alias: 'dining',
        component: icons.Dining,
    },
    {
        alias: 'electricity',
        component: icons.Electricity,
        styles: {
            top: 2
        }
    },
    {
        alias: 'ensuite',
        component: icons.Ensuite
    },
    {
        alias: 'entrance',
        component: icons.Entrance,
        styles: {
            top: 2
        }
    },
    {
        alias: 'exterior',
        component: icons.Exterior,
        styles: {
            top: 2
        }
    },
    {
        alias: 'garage',
        component: icons.Garage,
        styles: {
            top: 5
        }
    },
    {
        alias: 'garden',
        component: icons.Garden
    },
    {
        alias: 'kitchen',
        component: icons.Kitchen
    },
    {
        alias: 'laundry',
        component: icons.Laundry,
        styles: {
            top: 2
        }
    },
    {
        alias: 'living_room',
        component: icons.LivingRoom,
        styles: {
            top: 2
        }
    },
    {
        alias: 'lounge_room',
        component: icons.LivingRoom,
        styles: {
            top: 2
        }
    },
    {
        alias: 'roof',
        component: icons.Roof,
        styles: {
            top: 5
        }
    },
    {
        alias: 'safety',
        component: icons.Safety
    },
    {
        alias: 'shed',
        component: icons.Shed
    },
    {
        alias: 'toilet',
        component: icons.Toilet
    },
    {
        alias: 'utilities',
        component: icons.Utilities
    },
];
