import React from "react"

export function UserCard() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="98" height="73" viewBox="0 0 98 73">
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-140 -232) translate(140 232)">
            <circle cx="67" cy="31" r="31" fill="#DEDEDE"/>
            <g fill="#545454" fillRule="nonzero">
              <path
                d="M50.477 0c2.88 0 5.222 2.349 5.222 5.235l-.001 1.744h19.147c4.797 0 8.703 3.916 8.703 8.725v45.369c0 4.809-3.906 8.725-8.703 8.725H8.703C3.906 69.798 0 65.882 0 61.073V15.704C0 10.895 3.906 6.98 8.703 6.98l19.146-.001V5.235C27.85 2.349 30.192 0 33.071 0h17.406zM55.7 12.215c0 2.886-2.343 5.234-5.222 5.234H33.071c-2.879 0-5.222-2.348-5.222-5.234v-1.746H8.703c-2.879 0-5.222 2.35-5.222 5.235v45.369c0 2.886 2.343 5.235 5.222 5.235h66.142c2.88 0 5.222-2.349 5.222-5.235V15.704c0-2.886-2.343-5.234-5.222-5.234l-19.147-.001v1.746zM33.07 45.368c4.797 0 8.703 3.916 8.703 8.725v3.49c0 .963-.78 1.745-1.74 1.745-.961 0-1.741-.782-1.741-1.745v-3.49c0-2.886-2.343-5.235-5.222-5.235H19.147c-2.88 0-5.222 2.35-5.222 5.235v3.49c0 .963-.78 1.745-1.74 1.745-.962 0-1.741-.782-1.741-1.745v-3.49c0-4.809 3.905-8.725 8.703-8.725H33.07zm38.293 10.47c.961 0 1.74.782 1.74 1.745 0 .963-.779 1.745-1.74 1.745H50.477c-.96 0-1.74-.782-1.74-1.745 0-.963.78-1.745 1.74-1.745h20.887zm0-13.96c.961 0 1.74.782 1.74 1.746 0 .963-.779 1.744-1.74 1.744H50.477c-.96 0-1.74-.781-1.74-1.744 0-.964.78-1.745 1.74-1.745h20.887zM26.11 24.43c4.797 0 8.703 3.916 8.703 8.725 0 4.809-3.906 8.725-8.703 8.725-4.797 0-8.703-3.916-8.703-8.725 0-4.81 3.906-8.725 8.703-8.725zm0 3.49c-2.88 0-5.222 2.349-5.222 5.235s2.343 5.235 5.222 5.235c2.879 0 5.222-2.349 5.222-5.235s-2.343-5.235-5.222-5.235zm45.255 0c.961 0 1.74.782 1.74 1.745 0 .963-.779 1.745-1.74 1.745H50.477c-.96 0-1.74-.782-1.74-1.745 0-.963.78-1.745 1.74-1.745h20.887zM50.477 3.49H33.071c-.96 0-1.74.783-1.74 1.746v6.98c0 .963.78 1.745 1.74 1.745h17.406c.961 0 1.74-.782 1.74-1.745v-6.98c0-.963-.779-1.745-1.74-1.745z"
                transform="translate(0 3)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
