import axios, {AxiosPromise} from "axios";
import {Inspection} from "../types/Inspection";

export function getInspection(): AxiosPromise<Inspection> {
    return axios.get('/inspection')
}

export function uploadFile(file: string, area: string) {
    return axios.post('/inspection/upload', {
        file, area
    })
}

export function deleteFile(file_id: number) {
    return axios.delete(`/inspection/area/file/${file_id}`)
}

export function inspectionComment(section: string, comment: string) {
    return axios.put(`/inspection/comment`, {
        section,
        comment
    })
}


export function areaComment(area: string, comment: string) {
    return axios.put(`/inspection/area/comment`, {
        area,
        comment
    })
}

export function sign() {
    return axios.post(`/inspection/sign`)
}
