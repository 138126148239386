import React from "react";
import {Input, Avatar, message} from "antd";
import {inject, observer} from "mobx-react";
import {Area} from "../../../types/Area";
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";
import {Core} from "../../../store/Core/core";
import {Camera} from "../../../components/icons";
import {InspectionStore} from "../../../store/Inspection";
import {MAX_CAMERA_PHOTOS_COUNT} from "../../../components/images/CameraPicker/helpers";

const {TextArea} = Input;

interface Props {
    area: Area;
    index: number
    onFocus: (className: string) => void
}

interface InjectedProps extends Props {
    Core: Core
    Inspection: InspectionStore
}

@inject('Core', 'Inspection')
@observer
export class AreaCart extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    state = {
        comment: ''
    }

    timout: any = undefined;

    openCamera() {
        if (this.props.area.uploaded_files.length >= MAX_CAMERA_PHOTOS_COUNT) {
            return this.showWarningMessage();
        }

        this.injectedProps.Inspection.setActiveArea(this.props.area.id)
        this.injectedProps.Core.toggleCameraPicker()
    }

    openGallery(index: number) {
        this.injectedProps.Inspection.setActiveArea(this.props.area.id)
        this.injectedProps.Core.setGalleryIndex(index)
        this.injectedProps.Core.toggleGalleryDialog()
    }

    showWarningMessage() {
        message.warn('You can\'t upload more than 25 photos per area');
    }

    onTextChange(event: any) {
        this.setState({comment: event.target.value});

        if (this.timout) {
            clearTimeout(this.timout)
        }

        this.timout = setTimeout(() => {
            this.injectedProps
                .Inspection
                .addAreaComment(this.props.area.alias, this.state.comment)
        }, 1500)
    }

    componentDidMount(): void {
        const {area_comment} = this.props.area;
        this.setState({
            comment: area_comment ? area_comment.comment : ''
        })
    }

    render(): React.ReactElement {

        const inputClass = `comment-area-${this.props.area.alias}-${this.props.index}`;

        const files = this.props.area.uploaded_files;

        return (
            <div className="report-step__panel-container">
                <div className="fullwidth">
                    <div className="report-step__comment-area">
                        <TextArea
                            rows={4}
                            value={this.state.comment}
                            placeholder="Tenant comment"
                            className={`area-comment ${inputClass}`}
                            onFocus={() => this.props.onFocus(inputClass)}
                            onChange={(event) => this.onTextChange(event)}/>
                    </div>
                    <div className="report-step__camera-selector">
                    <span onClick={() => this.openCamera()}>
                        <Camera/>
                    </span>
                        <span className="uploaded-pictures-counter">
                        {files.length} of {MAX_CAMERA_PHOTOS_COUNT}
                    </span>
                    </div>
                </div>

                <div className="fullwidth">
                    <div className="uploaded-pictures-list">
                        {files.map((file, index) => {
                            return (
                                <span onClick={() => this.openGallery(index)} key={file.full_path}>
                                    <Avatar
                                        size={40}
                                        shape="square"
                                        className="area-photo"
                                        src={file.full_path}/>
                            </span>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
