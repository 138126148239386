import React from "react";
import {inject} from "mobx-react";
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";
import {InspectionStore} from "../../../store/Inspection";

import './styles.scss'
import {now} from "../../../helpers/date";

interface Props {
    onClose(): void
}

interface InjectedProps extends Props {
    Inspection: InspectionStore
}

@inject('Inspection')
export class TopBar extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    render(): React.ReactElement {
        const {onClose} = this.props
        const {tenant} = this.injectedProps.Inspection.inspection
        return (
            <div className="image-camera-top-bar">
                <div>
                    <i className="la la-calendar"/>
                    <span className="image-camera-top-bar__value-box">
                        {now()}
                    </span>
                </div>
                <div>
                    <i className="la la-user"/>
                    <span className="image-camera-top-bar__value-box">
                            {tenant.full_name}
                    </span>
                </div>
                <div className='close-btn' onClick={() => onClose()}>
                    <i className="la la-close"/>
                </div>
            </div>
        )
    }
}
