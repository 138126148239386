import React from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import {ThemeProvider} from "@material-ui/styles"
import {Provider} from "mobx-react"

import {themeOverrides} from "./themeOverrides"
import {AxiosMiddleware} from "./networking/middleware"
import {mockInit} from "./networking/mock"

import {MainLayout} from "./components"

import {routes} from "./routing/routes"

import Store from "./store"
import {InvalidPage, VerificationTenantPage, ExpiredPage} from "./pages"
import {EXPIRED_PAGE, INVALID_PAGE} from "./routing/paths"

import {getItem} from "./helpers/storage"

declare const ENV: string

const store = {
    Store,
    Lease: Store.Lease,
    Core: Store.Core,
    StaticPage: Store.StaticPage,
    Inspection: Store.Inspection
}

AxiosMiddleware.boot()

if (ENV === 'mock') {
    mockInit()
}

const App: React.FC = () => {
    return (
        <div className="App">
            <ThemeProvider theme={themeOverrides}>
                <Provider {...store}>
                    <Router>
                        <Switch>
                            <Route exact path={INVALID_PAGE} component={InvalidPage}/>
                            <Route exact path={EXPIRED_PAGE} component={ExpiredPage}/>
                            <Route exact path={'/:code'} component={VerificationTenantPage}/>
                            <MainLayout loadedInspection={true}>
                                <Switch>
                                    {routes.map((route, index) => {
                                            return <Route key={index}
                                                          exact={route.exact}
                                                          path={route.path}
                                                          render={(props) => {
                                                              const token = getItem('token')
                                                              if (route.private && !token) {
                                                                  return <Redirect to={{pathname: INVALID_PAGE}}/>
                                                              }
                                                              return <route.component {...props}
                                                                                      nextPath={route.nextPath}/>
                                                          }}/>
                                        }
                                    )}
                                    <Redirect to={{pathname: INVALID_PAGE}}/>
                                </Switch>
                            </MainLayout>
                            <Route component={InvalidPage}/>
                        </Switch>
                    </Router>
                </Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
