import React from "react";
import {Input} from "antd";
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";
import {inject, observer} from "mobx-react";
import {InspectionStore} from "../../../store/Inspection";

const {TextArea} = Input;

interface Props {
    alias: string
    onFocus: (className: string) => void
}

interface InjectedProps extends Props {
    Inspection: InspectionStore
}

@inject('Inspection')
@observer
export class CommentCart extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    state = {
        comment: ''
    }

    timout: any = undefined;

    onTextChange(event: any) {
        this.setState({comment: event.target.value});

        if (this.timout) {
            clearTimeout(this.timout)
        }

        this.timout = setTimeout(() => {
            this.injectedProps
                .Inspection
                .addComment(this.props.alias, this.state.comment)
        }, 1500)
    }

    componentDidMount(): void {
        const {alias} = this.props
        const {Inspection: {inspection}} = this.injectedProps
        this.setState({
            //@ts-ignore
            comment: inspection[`${alias}_comment`]
        })
    }

    render(): React.ReactElement {
        const inputClass = `comment-area-${this.props.alias}`;
        return (
            <div className="report-step__panel-container">
                <div className="report-step__comment-area">
                    <TextArea
                        rows={4}
                        placeholder="Tenant comment"
                        value={this.state.comment}
                        onFocus={() => this.props.onFocus(inputClass)}
                        onChange={(event => this.onTextChange(event))}
                        className={`area-comment ${inputClass}`}/>
                </div>
            </div>
        );
    }
}
