import React from "react"
import {inject, observer} from "mobx-react"
import {Button} from 'antd'

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons";

import {Props, InjectedProps} from "./types"

import './styles.scss'

@inject('Inspection', 'Core')
@observer
export class TenantDetailsSteps extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const {Core, Inspection: {inspection: {tenant}}} = this.injectedProps
        return (
            <LeaseStepsContainer title={'Tenancy Details'}>
                {{
                    content: (
                        <div className='tenant-details-step__container'>
                            <Icons.UserCard/>
                                <div className="tenant-details-step__tenant-card">
                                    <h5 className="tenant-details-step__tenant-card__heading">Primary Tenant</h5>
                                    <h4 className="heading-primary">{tenant.full_name}</h4>
                                    <h5
                                        className="heading-secondary">{tenant.email}</h5>
                                </div>
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}

            </LeaseStepsContainer>
        )
    }
}
