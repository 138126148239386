import React from 'react'
import {Button, message} from "antd";

import * as Icons from '../../components/icons'
import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"

require('../../helpers/clipboard')

export function CameraIssue() {

    const onClick = () => {
        const {location} = window
        const code = location.hash.replace('#', '');
        const url = location.origin + '/' + code
        if (navigator.clipboard === undefined) {
            //@ts-ignore
            window.Clipboard.copy(url)
            message.success('Url successfully copied')
            return;
        }
        navigator.clipboard.writeText(url).then(function () {
            message.success('Url successfully copied')
        }, function () {
            message.error('An error occur while copying url')
        });
    };
    return (
        <>
            <MainLayoutContentContainer>
                <div className="congratulation-page__container">
                    <h3 className="heading-red__bold-secondary" style={{margin: 0}}>
                        This browser does not support our camera feature
                    </h3>
                    <Icons.Oops/>
                    <p className="heading-secondary">
                        Please use Safari browser to complete your routine inspection
                    </p>
                </div>
            </MainLayoutContentContainer>
            <MainLayoutFooter>
                <div className="footer__buttons-container_two">
                    <div>
                        <Button
                            className="fullwidth t-uppercase app-basic-button_large"
                            shape="round"
                            type="danger"
                            loading={false}
                            onClick={() => onClick()}
                        >
                            Copy URL
                        </Button>
                    </div>
                </div>
            </MainLayoutFooter>
        </>
    )
}
