import React from 'react'
import {Button, Carousel as ResponsiveCarousel} from 'antd'
import {Props, State} from './types'

import './styles.scss'
import firstSlide from '../../resources/images/svg/carousel/first-slide.svg'
import secondSlide from '../../resources/images/svg/carousel/second-slide.svg'
import thirdSlide from '../../resources/images/svg/carousel/third-slide.svg'

export class Carousel extends React.Component<Props, State> {

    private ref = React.createRef<any>();

    state = {
        slide: 0
    };

    slideNext() {
        const {slide} = this.state
        if (slide === 2) {
            return this.props.onClose()
        }

        this.setState({
            slide: slide + 1
        }, () => {
            this.ref.current.next();
        })
    }

    render(): React.ReactElement {

        const lastStep = this.state.slide === 2

        return (
            <>
                <div className={'ant-carousel__separator'}>

                </div>
                <div className="ant-carousel__carousel-header">Tips for a great inspection</div>
                <ResponsiveCarousel
                    dotPosition="top"
                    swipe={false}
                    ref={this.ref}
                    beforeChange={(currentSlide: number, slide) => this.setState({slide})}
                >
                    <div>
                        <p className="ant-carousel__carousel-slide-text">
                            Your first picture should be a general photo of the entire area.
                            <br/>
                            This helps your Property Manager understand which area is being
                            inspected
                        </p>
                        <div className="ant-carousel__carousel-image">
                            <img src={firstSlide}
                                 alt="Tips for a great inspection"/>
                        </div>
                    </div>
                    <div>
                        <p className="ant-carousel__carousel-slide-text">
                            Take plenty of detailed photos in each area, particularly if you have
                            noticed an issue requiring maintenance
                        </p>
                        <div className="ant-carousel__carousel-image">
                            <img src={secondSlide}
                                 alt="Tips for a great inspection"/>
                        </div>
                    </div>
                    <div>
                        <p className="ant-carousel__carousel-slide-text">
                            Tap the microphone on your keyboard to dictate your comments and we’ll
                            convert them to text
                        </p>
                        <div className="ant-carousel__carousel-image">
                            <img src={thirdSlide}
                                 alt="Tips for a great inspection"/>
                        </div>
                    </div>
                </ResponsiveCarousel>
                <div className="ant-carousel__carousel-footer text-center">
                    <Button
                        className="t-uppercase"
                        shape="round"
                        type="danger"
                        loading={false}
                        onClick={() => {
                            this.slideNext()
                        }}
                    >
                        {!lastStep ? "Next" : 'Got it'}
                    </Button>
                </div>
            </>
        )
    }
}
