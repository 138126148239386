import React from "react";
// @ts-ignore
import ReactGallery from 'react-image-gallery';
import {inject, observer} from "mobx-react";
import {Modal} from "antd";
import {DeleteOutlined} from '@ant-design/icons'
import {Props, State, InjectedProps} from './types'
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";
import {TopBar} from "../TopBar/TobBar";
import './styles.scss'

const {confirm} = Modal;

@inject('Core', 'Inspection')
@observer
export class ImageGallery extends ComponentWithInjectedProps<Props, State, InjectedProps> {

    state = {
        images: [],
        currentIndex: 0
    }

    galleryRef = React.createRef()

    componentDidMount(): void {
        this.setIndex(this.injectedProps.Core.galleryIndex)
        this.mapImages()
    }

    setIndex(index: number) {
        this.injectedProps.Core.setGalleryIndex(index)
        this.setState({
            currentIndex: index
        })
    }

    mapImages() {
        // @ts-ignore
        const files = this.injectedProps.Inspection.getActiveAreaObj().uploaded_files
        const images = files.map((file) => {
            return {original: file.full_path}
        });
        this.setState({
            images
        })
    }

    handleDeleteClick() {
        confirm({
            title: null,
            icon: null,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            autoFocusButton: null,
            okButtonProps: {
                shape: 'round',
            },
            cancelButtonProps: {
                shape: 'round',
            },
            className: 'image-gallery-carousel__delete-modal',
            content: 'Are you sure you want to delete this picture?',
            onOk: () => {
                return this.injectedProps.Inspection.deleteAreaUploadedFile(
                    this.injectedProps.Core.galleryIndex
                ).then(() => this.handleImageDelete());
            },
        });
    }

    handleImageDelete() {
        const {currentIndex, images} = this.state
        const newImages = images.filter((value, index) => index !== this.state.currentIndex)
        const length = newImages.length
        let newIndex = currentIndex
        // in case if there is not images, we should close dialog
        if (!length) {
            return this.injectedProps.Core.toggleGalleryDialog()
        }

        if (currentIndex === length || currentIndex > length) {
            newIndex = currentIndex - 1
        }

        this.setState({
            images: newImages,
            currentIndex: newIndex
        }, () => {
            //@ts-ignore
            return this.galleryRef.current.slideToIndex(newIndex)
        })
    }


    render(): React.ReactElement {
        const {Core} = this.injectedProps
        const {images, currentIndex} = this.state
        return (
            <div className="image-gallery-carousel fade-in">
                <TopBar onClose={() => Core.toggleGalleryDialog()}/>
                <ReactGallery
                    ref={this.galleryRef}
                    items={images}
                    lazyLoad={true}
                    showNav={false}
                    autoPlay={false}
                    showIndex={false}
                    showThumbnails={false}
                    useTranslate3D={false}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    startIndex={Core.galleryIndex}
                    onSlide={(index: number) => this.setIndex(index)}/>
                <div className="image-gallery-carousel__footer">
                    <div>
                        {currentIndex + 1} of {images.length}
                    </div>
                    <div onClick={() => this.handleDeleteClick()}>
                        <DeleteOutlined/>
                    </div>
                </div>
            </div>
        )
    }
}
