import React from 'react'
import {Button, message} from "antd";
import './styles.scss'
import {sign} from "../../../networking/inspection";
import {MainLayoutContentContainer, MainLayoutFooter} from "../../../components";
import * as Icons from "../../../components/icons"
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {NextPath} from "../../../routing/types";
import {IInspection} from "../../../store/Inspection/types";
import {ICore} from "../../../store/Core/types";
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";
import {EXPIRED_PAGE} from "../../../routing/paths";

export interface Props extends RouteComponentProps, NextPath {

}

export interface InjectedProps extends Props {
    Inspection: IInspection
    Core: ICore
}

@inject('Inspection', 'Core')
@observer
export class ConfirmationStep extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    state = {
        loading: false
    }

    private handleClick() {
        this.setState({loading: true})
        sign()
            .then(() => {
                localStorage.removeItem('token')
                this.props.history.push(this.props.nextPath)
                this.setState({loading: false})
            })
            .catch(({response}) => {
                if (response.status === 422) {
                    return this.injectedProps.Inspection.loadInspection()
                        .then(() => {
                            this.props.history.goBack()
                            message.warn(response.data.message)
                            this.setState({loading: false})
                        })
                } else if (response.status === 423) {
                    this.props.history.push(EXPIRED_PAGE)
                }

                this.setState({loading: false})
            })
    }

    render() {
        return (

            <>
                <MainLayoutContentContainer>
                    <div className="congratulation-page__container">
                        <h3 className="heading-red__bold-secondary">
                            Your routine inspection is almost complete
                        </h3>
                        <Icons.Congratulation/>
                        <p className="heading-secondary">
                            Please make sure you have entered all the necessary information.<br/>
                            Once inspection report is sent back you will be unable to make any changes
                        </p>
                    </div>
                </MainLayoutContentContainer>
                <MainLayoutFooter>
                    <div className="footer__buttons-container_two">
                        <div>
                            <Button
                                className="fullwidth t-uppercase app-basic-button_large"
                                shape="round"
                                type="default"
                                loading={false}
                                onClick={() => this.props.history.goBack()}>
                                Go Back
                            </Button>
                        </div>
                        <div>
                            <Button
                                className="fullwidth t-uppercase app-basic-button_large"
                                shape="round"
                                type="danger"
                                loading={this.state.loading}
                                onClick={() => this.handleClick()}>
                                Send
                            </Button>
                        </div>
                    </div>
                </MainLayoutFooter>
            </>
        )
    }
}
