import React from "react"

import {Drawer} from 'antd'

import {Props} from "./types"

import './styles.scss'

export default function BottomDrawer(props: Props): React.ReactElement {
    const {onClose, visible, inspection} = props
    const manager = inspection.lease?.property.manager
    return (
        <Drawer
            height={193}
            title={`Would you like to call ${manager?.first_name + ' ' + manager?.last_name}?`}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            visible={visible}
            className="drawer-container"
        >
            <a className="drawer__content-container" href={`tel:${manager?.phone_number}`}>
                Call {manager?.phone_number}
            </a>
            <div className="drawer__cancel-container c-pointer" onClick={onClose}>
                Cancel
            </div>
        </Drawer>
    )
}
