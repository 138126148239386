import React from "react"
import {inject} from "mobx-react"
import {Button} from 'antd'
import ReactMapboxGl, {
    Marker,
    Popup
} from 'react-mapbox-gl'


import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {LeaseStepsContainer} from ".."

import {
    Props,
    InjectedProps
} from "./types"

import './styles.scss'

import marker from '../../../resources/images/svg/marker.svg'

declare const MAPBOX_TOKEN: string

const Map = ReactMapboxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: false,
    interactive: false
})

@inject('Inspection', 'Core')
export class PropertyWithMapsStep extends ComponentWithInjectedProps<Props, any, InjectedProps> {


    mapContainerStyle() {
        const height = window.innerHeight - 400;
        return {
            height: height < 200 ? 200 : height
        }
    }

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const {Inspection, Core} = this.injectedProps
        const {inspection: {lease: {property: {meta_data: {cords}, address}}}} = Inspection

        return (
            <LeaseStepsContainer title={'Property'}>
                {{
                    content: (
                        <div className="property-step__container">
                            <p className="heading-secondary text-center">
                                The address for your rental property
                                is:
                            </p>
                            <div className="property-step__maps-container"
                                 style={this.mapContainerStyle()}>
                                {/*eslint-disable-next-line*/}
                                <Map style="mapbox://styles/mapbox/streets-v9"
                                     containerStyle={{
                                         height: '100%',
                                         width: '100%'
                                     }}
                                     center={[cords.lng, cords.lat]}
                                     zoom={[13]}
                                >
                                    <Popup coordinates={[cords.lng, cords.lat]}>
                                        <span style={{fontFamily: 'CircularBook'}}>
                                            {address}
                                        </span>
                                    </Popup>
                                    <Marker
                                        coordinates={[cords.lng, cords.lat]}
                                        anchor="bottom">
                                        <img src={marker} alt="marker"/>
                                    </Marker>
                                </Map>
                            </div>
                        </div>
                    ),
                    footer: (
                        <div className="footer__buttons-container_two">
                            <div>
                                <Button
                                    className="fullwidth t-uppercase app-basic-button_large"
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className="fullwidth t-uppercase app-basic-button_large"
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
