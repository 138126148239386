import {Core} from "./Core/core"
import {StaticPage} from "./StaticPages"
import {InspectionStore} from "./Inspection";

class Store {
    Lease: any
    Core: any
    StaticPage: any
    Inspection: any

    constructor() {
        this.Core = new Core()
        this.StaticPage = new StaticPage()
        this.Inspection = new InspectionStore()
    }

}

export default new Store()
