import {action, computed, observable} from 'mobx'

import {ICore} from "./types"
import {Tenant} from "../../types/Tenant";

export class Core implements ICore {
  @observable tenant: Tenant = {
    full_name: '',
    email: '',
    phone_number: '',
  }

  @observable branding = {
    logo: null,
    accent_color: null
  };

  @observable isCameraVisible = false;

  @observable isVisibleCallDialog = false;

  @observable isGalleryVisible = false;

  @observable galleryIndex = 0;

  @observable openedArea = ["0"]

  @computed
  get VisibleCallDialog() {
    return this.isVisibleCallDialog
  }

  @computed
  get collapsedAreas() {
    return this.openedArea.slice();
  }

  @action
  setGalleryIndex(index: number) {
    this.galleryIndex = index
  }

  @action
  setOpenedAreas(areas: Array<string>) {
    this.openedArea = areas
  }

  @action
  setBranding(branding: { accent_color: null | string, logo: null | string }) {
    // @ts-ignore
    this.branding = branding
  }

  @action
  toggleCallDialog() {
    this.isVisibleCallDialog = !this.isVisibleCallDialog
  }

  @action
  toggleGalleryDialog() {
    this.isGalleryVisible = !this.isGalleryVisible
  }

  @action
  toggleCameraPicker() {
    this.isCameraVisible = !this.isCameraVisible
  }
}
