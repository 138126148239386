export const INDEX_PAGE = '/app/index'
export const WELCOME_PAGE = '/app/welcome'
export const TENANT_DETAILS_LEASE_STEP = '/app/tenancy-details'
export const PROPERTY_DETAILS_LEASE_STEP = '/app/property'
export const FILL_REPORT_STEP = '/app/report'
export const CONFIRMATION_STEP = '/app/confirmation'
export const CONGRATULATION_STEP = '/app/done'
export const CAMERA_ISSUE = '/app/camera-error'
export const INVALID_PAGE = '/error'
export const EXPIRED_PAGE = '/expired'
