import React from 'react'

export function Roof(props = {color: '#F93A73'}) {
    return (
        <svg width="30px"
             height="14px"
             viewBox="0 0 30 14"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons---Routine-Inspection" transform="translate(-421.000000, -27.000000)"
                   fill={props.color}>
                    <path d="M450.3426,41 C450.187851,41 450.033102,40.9459257 449.907911,40.8354514 L436.000179,28.5384999 L430.397326,33.4918183 C430.202585,33.6639256 429.926703,33.7052081 429.691971,33.5982224 C429.456659,33.4918183 429.305387,33.256915 429.305387,32.9975912 L429.305387,28.3187142 L426.250392,28.3187142 L426.250392,36.3949664 C426.250392,36.5699809 426.180842,36.7374367 426.05797,36.8612842 L422.122005,40.8069607 C421.864669,41.0645402 421.447947,41.0639588 421.19235,40.8069607 C420.935594,40.5493812 420.936173,40.1319046 421.19235,39.8743251 L424.935892,36.1216878 L424.935892,27.6593571 C424.935892,27.2953734 425.230322,27 425.593142,27 L429.962637,27 C430.325458,27 430.619887,27.2953734 430.619887,27.6593571 L430.619887,31.5375862 L435.56549,27.16513 C435.814711,26.9453443 436.185646,26.9453443 436.434868,27.16513 L450.77729,39.8464158 C451.049695,40.0871335 451.075776,40.5034471 450.835828,40.7767256 C450.706001,40.9244123 450.524011,41 450.3426,41 Z M435.999541,35.8405598 C435.079739,35.8405598 434.331494,36.5912036 434.331494,37.5139546 C434.331494,38.4367057 435.079739,39.1873494 435.999541,39.1873494 C436.919343,39.1873494 437.667588,38.4367057 437.667588,37.5139546 C437.667588,36.5912036 436.919343,35.8405598 435.999541,35.8405598 Z M435.999541,40.5054822 C434.355257,40.5054822 433.016994,39.1635103 433.016994,37.5139546 C433.016994,35.864399 434.355257,34.5218457 435.999541,34.5218457 C437.643825,34.5218457 438.982088,35.864399 438.982088,37.5139546 C438.982088,39.1635103 437.643825,40.5054822 435.999541,40.5054822 Z"
                          id="Combined-Shape"/>
                </g>
            </g>
        </svg>
    )
}
